/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Form, FormError, Input } from 'zent';
import Style, { DatePickerPanelStyle } from './style';
import cn from 'classnames';
import moment from 'moment';
import { useIntl } from 'react-intl';
import useTimeList from './useTimeList';
import usePickerAction from './usePickerAction';
import { isEmpty } from 'lodash';

const PICKER_TYPE = {
  year: 'year',
  month: 'month',
  date: 'date',
  extend: 'extend',
};

const language = window?.global_data?.multiLanguageInfo?.multiLanguage || 'en';

const monthMap = {
  'zh-CN': ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  'zh-TW': ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  ja: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  th: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
  id: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
  de: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'],
  it: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
};

const weekMap = {
  'zh-CN': ['日', '一', '二', '三', '四', '五', '六'],
  'zh-TW': ['日', '一', '二', '三', '四', '五', '六'],
  en: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  ja: ['日', '月', '火', '水', '木', '金', '土'],
  th: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
  id: ['M', 'S', 'S', 'R', 'K', 'J', 'S'],
  de: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
  it: ['S', 'D', 'L', 'M', 'M', 'G', 'V'],
};

const SubPickerPanel = (props) => {
  const { type = PICKER_TYPE.month, value = {}, onSelect = () => {}, disabledDate = () => {} } = props;
  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const monthTextMap = monthMap[language] || monthMap.en;

  const currentYear = value?.year;
  const currentMonth = value?.month;

  const onPageChange = (val) => {
    if (type === PICKER_TYPE.year) {
      setCurrentPage(currentPage + val);
      setCurrentList(Array.from({ length: 12 }, (_, index) => 1970 + (currentPage + val) * 12 + index));
    } else {
      onSelect(PICKER_TYPE.year, currentYear + val);
    }
  };

  useEffect(() => {
    if (type === PICKER_TYPE.year) {
      const page = Math.floor((currentYear - 1970) / 12);
      setCurrentList(Array.from({ length: 12 }, (_, index) => 1970 + page * 12 + index));
      setCurrentPage(page);
    } else {
      setCurrentList(Array.from({ length: 12 }, (_, index) => index + 1));
    }
  }, []);

  return (
    <div className='main-panel'>
      <div className='header'>
        <svg
          className={cn('header-icon', {
            'header-icon__disabled': type === PICKER_TYPE.year ? currentPage <= 0 : currentYear <= 1970,
          })}
          aria-hidden='true'
          width={24}
          height={24}
          onClick={() => onPageChange(-1)}
        >
          <use xlinkHref='#iconic-zuoyouqiehuan' />
        </svg>
        <div className='header-title'>
          {type === PICKER_TYPE.year ? `${currentList?.[0]}-${currentList?.[11]}` : currentYear}
        </div>
        <svg className={cn('header-icon')} aria-hidden='true' width={24} height={24} onClick={() => onPageChange(1)}>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      </div>
      <div className='content'>
        {currentList.map((item) => {
          const itemDisabled =
            type === PICKER_TYPE.year ? disabledDate({ year: item }) : disabledDate({ year: currentYear, month: item });

          const itemActive = type === PICKER_TYPE.year ? item === currentYear : item === currentMonth;

          return (
            <div
              className={cn('content-item', {
                'content-item_active': itemActive,
                'content-item_disabled': itemDisabled,
              })}
              key={item}
              onClick={() => {
                if (!itemDisabled) {
                  onSelect(type, item);
                }
              }}
            >
              {type === PICKER_TYPE.month ? monthTextMap[item - 1] : item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DatePickerPanel = forwardRef((props, ref) => {
  const {
    value = '',
    showExtend = false,
    extendList = [],
    extendValue = extendList?.[0] || '',
    intl,
    onChange = () => {},
    onConfirm = () => {},
    disabledDate = () => {},
  } = props;

  const weekList = weekMap[language] || weekMap.en;
  const monthTextMap = monthMap[language] || monthMap.en;
  const valueMoment = value ? moment(value) : moment();

  const {
    calendarPickerVisible,
    yearPickerVisible,
    monthPickerVisible,
    openSubPanel,

    current,
    setCurrent,
    onSelect,
    todayBtnDisabled,
    preMonthDisabled,
    nextMonthDisabled,
    dateList,
  } = usePickerAction({
    value: valueMoment,
    extendValue,
    disabledDate,
    onChange,
  });
  const confirmBtnDisabled =
    !current.year || !current.month || !current.date || (!current.extend && showExtend) || disabledDate(current);

  const onSelectToday = () => {
    const today = moment();
    setCurrent({ year: today.year(), month: today.month() + 1, date: today.date(), extend: extendList?.[0] || '' });
  };

  return (
    <DatePickerPanelStyle ref={ref} onClick={(e) => e.stopPropagation()}>
      {calendarPickerVisible && (
        <>
          <div className='panel'>
            <div className='main-panel'>
              <div className='header'>
                <svg
                  className={cn('header-icon', {
                    'header-icon__disabled': preMonthDisabled,
                  })}
                  aria-hidden='true'
                  onClick={() => {
                    if (!preMonthDisabled) {
                      onSelect(PICKER_TYPE.month, current.month - 1);
                    }
                  }}
                >
                  <use xlinkHref='#iconic-zuoyouqiehuan' />
                </svg>
                <div className='header-title'>
                  <div className='header-year' onClick={(e) => openSubPanel(e, PICKER_TYPE.year)}>
                    {`${current.year}${['zh-CN', 'zh-TW'].includes(language) ? '年' : ''}`}
                    <svg className='header-icon' aria-hidden='true'>
                      <use xlinkHref='#iconic-xiala1' />
                    </svg>
                  </div>
                  <div className='header-month' onClick={(e) => openSubPanel(e, PICKER_TYPE.month)}>
                    {monthTextMap[current.month - 1]}
                    <svg className='header-icon' aria-hidden='true'>
                      <use xlinkHref='#iconic-xiala1' />
                    </svg>
                  </div>
                </div>
                <svg
                  className={cn('header-icon', {
                    'header-icon__disabled': nextMonthDisabled,
                  })}
                  aria-hidden='true'
                  onClick={(e) => {
                    if (!nextMonthDisabled) {
                      onSelect(PICKER_TYPE.month, current.month + 1);
                    }
                  }}
                >
                  <use xlinkHref='#iconic-xiala' />
                </svg>
              </div>
              <div className='calendar'>
                <div className='calendar-header'>
                  {weekList.map((item, index) => (
                    <div className='calendar-item' key={index}>
                      {item}
                    </div>
                  ))}
                </div>
                <div className='calendar-content'>
                  {dateList?.map((item, index) => {
                    const dateDisabled = disabledDate({ ...current, date: item, extend: '' });
                    const isToday =
                      current.year === moment().year() &&
                      current.month === moment().month() + 1 &&
                      item === moment().date();

                    return (
                      <div
                        className={cn('calendar-item', {
                          'calendar-item_active': item === current.date,
                          'calendar-item_disabled': dateDisabled,
                          'calendar-item_today': isToday,
                        })}
                        key={index}
                        onClick={() => {
                          if (item && !dateDisabled) {
                            onSelect(PICKER_TYPE.date, item);
                          }
                        }}
                      >
                        {item || ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {showExtend && (
              <div className='extend-panel'>
                <div className='header'>
                  <div className='header-title'>{current.extend}</div>
                </div>
                <div className='time-picker'>
                  {extendList.map((item) => {
                    return (
                      <div
                        className={cn('time-picker-item', {
                          'time-picker-item-active': item === current.extend,
                        })}
                        onClick={() => onSelect(PICKER_TYPE.extend, item)}
                        key={item}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='footer'>
            <Button type='link' size='small' disabled={todayBtnDisabled} className='today-btn' onClick={onSelectToday}>
              {intl?.formatMessage({ defaultMessage: '今天', id: 'general.today' })}
            </Button>
            <Button
              type='primary'
              size='small'
              onClick={() => {
                onConfirm({
                  date: moment([current.year, current.month - 1, current.date]).format('YYYY-MM-DD'),
                  extend: current.extend,
                  // year: current.year,
                  // month: current.month,
                  // date: current.date,
                });
              }}
              disabled={confirmBtnDisabled}
            >
              {intl?.formatMessage({ defaultMessage: '确定', id: 'common_confirm' })}
            </Button>
          </div>
        </>
      )}
      {yearPickerVisible && (
        <SubPickerPanel type={PICKER_TYPE.year} onSelect={onSelect} value={current} disabledDate={disabledDate} />
      )}
      {monthPickerVisible && (
        <SubPickerPanel type={PICKER_TYPE.month} onSelect={onSelect} value={current} disabledDate={disabledDate} />
      )}
    </DatePickerPanelStyle>
  );
});

const DeliveryTimePicker = (props) => {
  const {
    className = '',
    name = '',
    defaultValue = '',
    validators = [],
    settings = {},
    getSelectValue = () => {},
    disabled = false,
    isPickup,
  } = props;

  const intl = useIntl();

  const contaienrRef = useRef();
  const pickerRef = useRef();

  const [pickerVisible, setPickerVisible] = useState(false);
  const [extendList, setExtendList] = useState([]);
  const [showExtend, setShowExtend] = useState(true);

  const {
    deliveryTimeCustomDetails: timeCustomDetails,
    deliveryTimeInterval: timeInterval,
    expectedDeliveryTimeUnit: expectedTimeUnit,
    expectedDeliveryTimeValue: expectedTimeValue = 0,
    isEnableDeliveryTime: isEnableTime = 0,
    isEnableExpectedDeliveryTime: isEnableExpectedTime = 0,
    longestExpectedTime = 0,
    deliveryTimeIntervalDetail: timeIntervalDetail,
  } = settings;

  const { getPickerDateList, rangeOriginList } = useTimeList({
    expectedTimeUnit,
    expectedTimeValue,
    isEnableExpectedTime,
    longestExpectedTime,
    timeCustomDetails,
    timeIntervalDetail,
    timeInterval,
  });

  const disabledDate = (current) => {
    const curYear = current.year;
    const curMonth = current.month;
    const curDate = current.date;
    const curExtend = current.extend;
    if (
      curDate &&
      curMonth &&
      curYear &&
      curExtend &&
      rangeOriginList?.[`${curYear}`]?.[`${curMonth}`]?.[`${curDate}`]?.list?.indexOf(curExtend) === -1
    ) {
      // 时间段不可选
      return true;
    }
    if (
      curDate &&
      curMonth &&
      curYear &&
      rangeOriginList?.[`${curYear}`]?.[`${curMonth}`]?.[`${curDate}`] === undefined
    ) {
      // 日期不可选
      return true;
    }
    if (curMonth && curYear && rangeOriginList?.[`${curYear}`]?.[`${curMonth}`] === undefined) {
      // 月份不可选
      return true;
    }
    if (curYear && rangeOriginList?.[`${curYear}`] === undefined) {
      // 年份不可选
      return true;
    }

    // 可选
    return false;
  };

  const deliveryTimeValidator = (value, ctx) => {
    if (!isEnableTime) {
      return null;
    }
    if (!value?.date || !value?.full) {
      return {
        name: 'required',
        message: isPickup
          ? intl.formatMessage({ id: '0e0cd304dc594512ba18682ec69b36a3', defaultMessage: '请选择提货时间' })
          : intl.formatMessage({ id: 'delivery.time.can.not.be.null', defaultMessage: '请选择配送时间' }),
      };
    } else {
      const year = moment(value.date).year();
      const month = moment(value.date).month() + 1;
      const date = moment(value.date).date();
      const extend = showExtend ? `${value.start}-${value.end}` : '';
      if (disabledDate({ year, month, date, extend })) {
        return {
          name: 'invalid',
          message: isPickup
            ? intl.formatMessage({ id: 'pickup.time.invalid', defaultMessage: '提货时间失效，请重新选择提货时间' })
            : intl.formatMessage({ id: 'delivery.time.invalid', defaultMessage: '配送时间失效，请重新选择配送时间' }),
        };
      }
      return null;
    }
  };
  const field = Form.useField(name, defaultValue, [...validators, deliveryTimeValidator]);
  field.destroyOnUnmount = true;

  const onFocus = () => {
    if (isEnableTime) {
      setPickerVisible(true);
    }
  };

  const bindBodyClick = (e) => {
    console.log('???bindBodyClick', e, contaienrRef);
    if (contaienrRef?.current?.contains(e.target)) {
      return;
    }
    setPickerVisible(false);
  };

  useEffect(() => {
    if (!field.value?.date) {
      const year = moment().year();
      const month = moment().month() + 1;
      const date = moment().date();
      onPanelChange({ year, month, date });
    }
  }, [field.value]);

  useEffect(() => {
    if (isEnableTime) {
      getPickerDateList().then((res) => {
        if (res?.onlyDate) {
          setShowExtend(false);
        } else {
          setShowExtend(true);
        }
      });
    }
  }, [settings]);

  useEffect(() => {
    if (pickerVisible) {
      const disBottom = document.body.clientHeight - pickerRef.current.getBoundingClientRect().top;
      const pickerHeight = pickerRef.current.getBoundingClientRect().height;
      if (disBottom < pickerHeight) {
        pickerRef.current.style.top = '-4px';
        pickerRef.current.style.transform = 'translateY(-100%)';
      }
      document.addEventListener('click', bindBodyClick, false);
    } else {
      document.removeEventListener('click', bindBodyClick, false);
    }
    return () => {
      document.removeEventListener('click', bindBodyClick, false);
    };
  }, [pickerVisible]);

  const onPanelChange = ({ year, month, date }) => {
    const dateInfo = rangeOriginList?.[year]?.[month]?.[date];
    if (showExtend && year && month && date && !!dateInfo) {
      setExtendList(dateInfo.list);
    }
  };

  const onPanelConfirm = ({ date, extend = '' }) => {
    field.value = {
      date,
      start: extend?.split('-')?.[0] || '',
      end: extend?.split('-')?.[1] || '',
      full: `${moment(date).format('YYYY-MM-DD')} ${extend}`,
    };
    getSelectValue(field.value);
    field.validate();
    setPickerVisible(false);
  };

  return (
    <Style className={cn(className, 'skiptranslate')}>
      <div ref={contaienrRef} className='deliver-time-input-container'>
        <Input
          className={cn('deliver-time-input', { 'deliver-time-input_error': !!field.error })}
          value={
            isEnableTime
              ? field.value?.full || ''
              : isPickup
              ? intl.formatMessage({ id: '0acc93f488dd4be28f8035567935c825', defaultMessage: '默认提货时间' })
              : intl.formatMessage({ id: 'a1ef24f1bc54442e8c32a281f3f5f2bc', defaultMessage: '默认配送时间' })
          }
          placeholder={intl.formatMessage({
            id: 'common_please_select',
            defaultMessage: '请选择',
            description: '请选择',
          })}
          onFocus={onFocus}
          disabled={!isEnableTime || disabled}
          onChange={() => {}}
        />
        <svg className='deliver-time-icon' aria-hidden='true' width={24} height={24}>
          <use xlinkHref='#iconrili' />
        </svg>

        {pickerVisible && (
          <DatePickerPanel
            ref={pickerRef}
            value={field.value?.date}
            showExtend={showExtend}
            extendList={extendList}
            extendValue={
              field.value?.start && field.value?.end ? `${field.value?.start}-${field.value?.end}` : extendList[0]
            }
            intl={intl}
            onChange={onPanelChange}
            onConfirm={onPanelConfirm}
            disabledDate={disabledDate}
          />
        )}
      </div>
      <FormError>{field.error?.message}</FormError>
    </Style>
  );
};

export default DeliveryTimePicker;
